//Бухгалтерия
import React from "react";
import axios from "axios";
import Select from "react-select";
import Box from '@mui/material/Box';
import elida from '../images/elida.svg';
import raiffeisen from '../images/raiffeisen.png'
import oplacheno from '../images/oplacheno.png'
import sber from '../images/sber.png'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import {
    DOMEN_SERVER, TASK_ORG_LIST, MANEGMENT_SUBJECTS_ORG, ACCOUNTING_ADD_ENTRIES, ACCOUNTING_ENTRIES_LIST, MANEGMENT_SERVICE_LIST,
    ACCOUNTING_ENTRIES_UPDATE, ACCOUNTING_ENTRIES_DELETE, ACCOUNTING_SERVICE_PRICE, ACCOUNTING_APP_REPORTS, ACCOUNTING_APP_STATUS_END, MANEGMENT_COUNTERPARTIES,
    ACCOUNTING_COUNTER_ENTRIES, ACCOUNTING_CREATE_BILL, ACCOUNTING_INVOICE_VIEW, ACCOUNTING_COUN_JOURNAL, ACCOUNTING_DELETE_INVOICE, ACCOUNTING_COUNTERPARTIES,
    ACCOUNTING_ITEMS, ACCOUNTING_ADD_ITEM, ACCOUNTING_GROUP_ITEMS, ACCOUNTING_ACCEPT_PAYMENT, ACCOUNTING_PERSONAL, ACCOUNTING_PAY_EXPENSE, ACCOUNTING_CASHBOX_TOP,
    ACCOUNTING_ADD_SALARY, ACCOUNTING_SALARY_TOP, ACCOUNTING_SALARY_CALCULATION, ACCOUNTING_PERSONAL_LIST_ID, ACCOUNTING_PERSONAL_LIST, ACCOUNTING_SERVICE_AGR, ACCOUNTING_NEW_AGR,
    ACCOUNTING_AGR_ROW_REQUEST, ACCOUNTING_AGR_DELETE, ACCOUNTING_SERVICE_ACTUAL_PRICE, ACCOUNTING_CHECK_VIEW, ACCOUNTING_CASH_SELECT, ACCOUNTING_LOCALE_TRANSFER, ACCOUNTING_TOTAL_REPORT, ACCOUNTING_PASSIVE_DELETE, ACCOUNTING_PASSIVIES, ACCOUNTING_PASSIVE_ADD, ACCOUNTING_CREDIT_INVOICE, ACCOUNTING_SHAREHOLDERS, ACCOUNTING_DIVIDEND, ACCOUNTING_DIVIDENDS, ACCOUNTING_DIVIDEND_USER
} from "../Config/Base";
import { DataGrid, GridToolbarContainer, GridToolbarExport, ruRU, GridRowModes, GridActionsCellItem, } from "@mui/x-data-grid";
import { Alert, Button, Container, Form, Modal, Row, Col, Table, Stack } from "react-bootstrap";
import { SessionEndend } from "./Auth";

//Загрузчик
export class Spiner extends React.Component {
    render() {
        return (<>
            <div key="loadDiv" className="overlay" >
                <div className="d-flex justify-content-center">
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                    <div className="spinner-grow text-primary" role="status" ></div>
                </div>
                <span className="m-2"> Loading...</span>
            </div>
        </>)
    }
}
//добавить проводку
export class AddEntries extends React.Component {
    constructor(props) {
        super(props);
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        const dd = new Date().toJSON().slice(0, 10);
        this.state = {
            email: '', organization_id: 0, org_list: '', category_id: '', category_list: [], service_id: '', service_list: [],
            price: '', num_article: '', num_operation: 1, date: dd, show: true, spiner: '', uielem: [], alertSow: false, coment: '',
            alertMessage: '', customStyles: customStyles,
        };
        this.handleChange = this.handleChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
    }
    handleChange(event) {

        this.setState({ [event.target.name]: [event.target.value] });
    }
    inputChange(event) {

        this.setState({ [event.target.name]: event.target.value });

    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ org_list: res.data, spiner: '' });
        });
    }
    handleClose = () => {
        this.props.closeModal();
    }
    orgChange = (event) => {
        this.setState({ organization_id: event.value, category_id: '', service_id: '', uielem: 0, price: '', num_article: '', num_operation: 1, spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ACCOUNTING_SERVICE_PRICE, { organization_id: event.value }).then(res => {
            this.setState({ service_list: res.data });
        });
        axios.post(DOMEN_SERVER + MANEGMENT_SUBJECTS_ORG, { organization_id: event.value }).then(res => {
            this.setState({ category_list: res.data, spiner: '', uielem: 1 });
        });
    }
    categoryChange = (event) => {
        this.setState({ category_id: event.value });
    }
    serviceChange = (event) => {
        this.setState({ price: event.price, service_id: event.value });
    }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    saveEntries = () => {
        const organization_id = this.state.organization_id;
        const service_id = this.state.service_id;
        const service_price = this.state.price;
        const service_count = this.state.num_operation;
        const public_date = this.state.date;
        const subject_id = this.state.category_id;
        const subject_count = this.state.num_article;
        const coment = this.state.coment;
        const email = this.state.email;
        if (organization_id > 0 && service_id > 0 && service_price > 0 && service_count > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_ADD_ENTRIES, {
                organization_id: organization_id,
                service_id: service_id,
                service_price: service_price,
                service_count: service_count,
                public_date: public_date,
                subject_id: subject_id,
                subject_count: subject_count,
                coment: coment,
                email: email,
            }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            }).catch(() => {
                this.message({ variant: 'danger', message: 'Ошибка на сервере' });
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let control = '';
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        if (this.state.uielem > 0) {
            control = (<>
                <div className="mb-1 " ><cite>Изделие - количество - услуга</cite>
                    <div className="d-flex"><Select options={this.state.category_list} styles={this.state.customStyles} className="me-1 w-100" placeholder="Категория" onChange={this.categoryChange} />
                        <Form.Control key={1} id="1" type="number" className="ms-1" name="num_article" placeholder="Кол-во товара" value={this.state.num_article} onChange={this.inputChange} />
                        <Select options={this.state.service_list} styles={this.state.customStyles} className="ms-1 w-100" placeholder="Услуги" onChange={this.serviceChange} />

                    </div></div>
                <div className="mb-1 "><cite>Количество операций - цена - сумма</cite>
                    <div className="d-flex">
                        <Form.Control key={3} id="3" type="number" className="me-1" name="num_operation" placeholder="Количество услуг" value={this.state.num_operation} onChange={this.inputChange} />
                        <Form.Control key={2} id="2" type="number" className="ms-1" name="price" placeholder="Цена" value={this.state.price} onChange={this.inputChange} />
                        <Form.Control key={4} id="4" type="number" disabled className="ms-1" name="price" value={this.state.price * this.state.num_operation} />
                    </div></div>
                <div className="mb-1 ">
                    <div className="d-flex">
                        <Form.Control key={6} id="6" type="text" placeholder="Примечание" className="me-1" name="coment" value={this.state.coment} onChange={this.inputChange} />
                        <Form.Control key={5} id="5" type="date" className="ms-1" name="date" placeholder="Дата" value={this.state.date} onChange={this.inputChange} />
                        <div><Button variant="primary" className="ms-1" onClick={this.saveEntries}>Сохранить</Button></div>
                    </div></div> </>
            );
        }

        return (<>
            {this.state.spiner}
            <div>
                <div className="mb-1"><Select options={this.state.org_list} styles={this.state.customStyles} placeholder="Организация" onChange={this.orgChange} /> </div>
                {control}
            </div>
            {message}
        </>);
    }
}
//Добавить проводку окно
export class AddEntriesModal extends React.Component {
    constructor(props) {
        super(props);

        const dd = new Date().toJSON().slice(0, 10);
        this.state = {
            email: '', organization_id: 0, org_list: '', category_id: '', category_list: [], service_id: '', service_list: [],
            price: '', num_article: '', num_operation: 1, date: dd, show: true, spiner: '', uielem: [], alertSow: false, coment: '',
            alertMessage: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.inputChange = this.inputChange.bind(this);
    }
    handleChange(event) {

        this.setState({ [event.target.name]: [event.target.value] });
    }
    inputChange(event) {

        this.setState({ [event.target.name]: event.target.value });

    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + TASK_ORG_LIST).then(res => {
            this.setState({ org_list: res.data });
        });
        axios.post(DOMEN_SERVER + MANEGMENT_SERVICE_LIST).then(res => {
            this.setState({ service_list: res.data, spiner: '' });
        });
    }
    handleClose = () => {
        this.props.closeModal();
    }
    orgChange = (event) => {

        this.setState({ organization_id: event.value, category_id: '', service_id: '', uielem: 0, price: '', num_article: '', num_operation: 1, spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + MANEGMENT_SUBJECTS_ORG, { organization_id: event.value }).then(res => {
            this.setState({ category_list: res.data, spiner: '', uielem: 1 });
        });
    }
    categoryChange = (event) => {
        this.setState({ category_id: event.value });
    }
    serviceChange = (event) => {
        this.setState({ price: event.price, service_id: event.value });
    }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    saveEntries = () => {
        const organization_id = this.state.organization_id;
        const service_id = this.state.service_id;
        const service_price = this.state.price;
        const service_count = this.state.num_operation;
        const public_date = this.state.date;
        const subject_id = this.state.category_id;
        const subject_count = this.state.num_article;
        const coment = this.state.coment;
        const email = this.state.email;
        if (organization_id > 0 && service_id > 0 && service_price > 0 && service_count > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_ADD_ENTRIES, {
                organization_id: organization_id,
                service_id: service_id,
                service_price: service_price,
                service_count: service_count,
                public_date: public_date,
                subject_id: subject_id,
                subject_count: subject_count,
                coment: coment,
                email: email,
            }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            }).catch(() => {
                this.message({ variant: 'danger', message: 'Ошибка на сервере' });
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let control = '';
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        if (this.state.uielem > 0) {
            control = (<>
                <div className="mb-1 " ><cite>Категория товара - количество</cite>
                    <div className="d-flex"><Select options={this.state.category_list} className="me-1 w-100" placeholder="Категория" onChange={this.categoryChange} />
                        <Form.Control key={1} id="1" type="number" className="ms-1" name="num_article" placeholder="Кол-во товара" value={this.state.num_article} onChange={this.inputChange} />
                    </div></div>
                <div className="mb-1 "><cite>Наши услуги - цена</cite>
                    <div className="d-flex"><Select options={this.state.service_list} className="me-1 w-100" placeholder="Услуги" onChange={this.serviceChange} />
                        <Form.Control key={2} id="2" type="number" className="ms-1" name="price" placeholder="Цена" value={this.state.price} onChange={this.inputChange} />
                    </div></div>
                <div className="mb-1 "><cite>Количество услуг - сумма</cite>
                    <div className="d-flex">
                        <Form.Control key={3} id="3" type="number" className="me-1" name="num_operation" placeholder="Количество услуг" value={this.state.num_operation} onChange={this.inputChange} />
                        <Form.Control key={4} id="4" type="number" disabled className="ms-1" name="price" value={this.state.price * this.state.num_operation} />
                    </div></div>
                <div className="mb-1 ">
                    <div className="d-flex">
                        <Form.Control key={6} id="6" type="text" placeholder="Примечание" className="me-1" name="coment" value={this.state.coment} onChange={this.inputChange} />
                        <Form.Control key={5} id="5" type="date" className="ms-1" name="date" placeholder="Дата" value={this.state.date} onChange={this.inputChange} />
                    </div></div></>
            );
        }
        return (<>
            {this.state.spiner}

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Новая проводка</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="mb-1"><Select options={this.state.org_list} placeholder="Организация" onChange={this.orgChange} /> </div>
                        {control}
                    </div>
                </Modal.Body>
                {message}
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>Закрыть</Button>
                    <Button variant="primary" onClick={this.saveEntries}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </>);
    }
}
//Заявка
export class ReportApplication extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', applicationList: [], spiner: '', show: true, col: [], rows: [], rowModesModel: {}, setRowModesModel: {}, alertSow: false,
            alertMessage: '',
        }
    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        // axios.post(DOMEN_SERVER + ACCOUNTING_APP_LIST_END).then(res => {
        //     this.setState({ applicationList: res.data, spiner: '' });
        // });
        this.columns();
        this.orgChange();
    }
    handleClose = () => { this.props.closeModal(); }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    orgChange = () => {
        //ACCOUNTING_APP_REPORTS
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ACCOUNTING_APP_REPORTS).then(res => {
            this.setState({ spiner: '', rows: res.data });
        });
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const upRow = this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        this.setState({ rows: upRow });
        return updatedRow;
    }
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        //console.log(newRowModesModel); не понятный
        this.setState({ setRowModesModel: newRowModesModel });
    };
    handleSaveClick = (id) => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }
    };
    handleEditClick = (id) => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    }
    handleDeleteClick = (id) => {
        if (window.confirm('Запись - ' + id + ' не добавлять в проводку?')) {
            this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
            axios.post(DOMEN_SERVER + ACCOUNTING_APP_STATUS_END, { subaplication_id: id });
        }
    }
    handleSaveRecordClick = (id) => {
        const saveRow = this.state.rows.filter((row) => row.id === id);
        const subaplication_id = saveRow[0].id;
        const application_id = saveRow[0].application_id;
        const organization_id = saveRow[0].organization_id;
        const service_id = saveRow[0].service_id;
        const service_price = saveRow[0].service_price;
        const service_count = saveRow[0].service_count;
        const D = new Date(saveRow[0].public_date);
        const public_date = D.toJSON().slice(0, 10);
        const subject_id = saveRow[0].subject_id;
        const subject_count = saveRow[0].subject_count;
        const coment = saveRow[0].coment;
        const email = this.state.email;
        if (organization_id > 0 && service_id > 0 && service_price > 0 && service_count > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_ADD_ENTRIES, {
                organization_id: organization_id,
                application_id: application_id,
                service_id: service_id,
                service_price: service_price,
                service_count: service_count,
                public_date: public_date,
                subject_id: subject_id,
                subject_count: subject_count,
                coment: coment,
                email: email,
            }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            }).catch(() => {
                this.message({ variant: 'danger', message: 'Ошибка на сервере' });
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
        axios.post(DOMEN_SERVER + ACCOUNTING_APP_STATUS_END, { subaplication_id: subaplication_id });
        this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
    }
    columns = () => {
        const col = [{
            field: 'id', headerName: 'ID', width: 60, headerAlign: 'center',
        },
           { field: 'application_id', headerName: 'Заявка', width: 100, headerAlign: 'center', align:'center',
        },
        { field: 'organizations', headerName: 'Заказчик', width: 180, headerAlign: 'center', },
        { field: 'subject', headerName: 'Товар', width: 150, headerAlign: 'center', valueGetter: (params) => params.row.subject + '-' + params.row.subject_count, },
        { field: 'service', headerName: 'Услуга', width: 150, headerAlign: 'center', },
        { field: 'service_count', headerName: 'Кол-во', width: 80, headerAlign: 'center', align: 'right', editable: true, },
        { field: 'service_price', headerName: 'Цена', width: 80, headerAlign: 'center', align: 'right', editable: true, },
        { field: 'total_sum', headerName: 'Сумма', width: 120, headerAlign: 'center', align: 'right', valueGetter: (params) => params.row.service_count * params.row.service_price, },
        { field: 'coment', headerName: 'Примечание', width: 150, headerAlign: 'center', editable: true, },
        { field: 'public_date', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), editable: true, type: 'date' },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={() => this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={() => this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        sx={{
                            color: 'primary.main',
                        }}
                        onClick={() => this.handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        sx={{
                            color: 'red',
                        }}
                        onClick={() => this.handleDeleteClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<SaveIcon />}
                        label="Save"
                        sx={{
                            color: 'green',
                        }}
                        onClick={() => this.handleSaveRecordClick(id)}
                        color="inherit"
                    />,
                ];
            },

        },
        ];
        this.setState({ col: col, });
    }
    render() {
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        return (<>
            {this.state.spiner}
            <Modal show={this.state.show} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление в проводки</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="mb-3"><h6>Добавление в проводки по завершенным заявкам</h6> </div>
                    </div>

                    <div>
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                                editMode="row"
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                processRowUpdate={this.processRowUpdate}
                                onProcessRowUpdateError={this.handleProcessRowUpdateError}
                                rowModesModel={this.state.rowModesModel}
                                onRowModesModelChange={this.handleRowModesModelChange}
                            />
                        </Box>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {message}
                </Modal.Footer>

            </Modal>
        </>);
    }
}
//Инвойс
export class Invoice extends React.Component {
    constructor(props) {
        super(props);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dd = new Date().toLocaleDateString(undefined, options);
        const time = new Date().toLocaleTimeString();
        this.state = { options: {}, show: true, date: dd, time: time, spiner: '', data: [], organizations: [], entries: [] }
    }
    handleClose = () => {
        this.props.closeModal();
    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + ACCOUNTING_INVOICE_VIEW, { id: this.props.id }).then(res => {
            this.setState({ data: res.data, organizations: res.data.organizations, entries: res.data.entries, spiner: '' });
        });
    }
    componentDidUpdate(prevProps, prevState) {

        if (this.props.pdf === true) {
            if (prevState.data != this.state.data) {
                this.pdfGenerate();
                this.props.closeModal();
            }
        }
    }
    //generator PDF
    getTargetElement = () => document.getElementById('content-id');
    pdfGenerate = () => {
        this.setState({ spiner: <Spiner /> });
        var name = this.state.data.counterparty;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        const filename = name + '_счет_' + this.props.id;
        const options = {
            filename: filename,
            // default is `save`,'open'
            method: 'save',
            // default is Resolution.MEDIUM = 3, which should be enough, higher values
            // increases the image quality but also the size of the PDF, so be careful
            // using values higher than 10 when having multiple pages generated, it
            // might cause the page to crash or hang.
            resolution: Resolution.LOW,
            page: {
                // margin is in MM, default is Margin.NONE = 0.'SMALL'
                margin: Margin.SMALL,
                // default is 'A4','letter'
                format: 'A4',
                // default is 'portrait','landscape'
                orientation: 'portrait',
            },
            canvas: {
                // default is 'image/jpeg' for better size performance
                mimeType: 'image/png',
                qualityRatio: 1
            },
            // Customize any value passed to the jsPDF instance and html2canvas
            // function. You probably will not need this and things can break, 
            // so use with caution.
            overrides: {
                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                pdf: {
                    compress: true
                },
                // see https://html2canvas.hertzen.com/configuration for more options
                canvas: {
                    useCORS: true
                }
            },
        };

        generatePDF(this.getTargetElement, options);
        this.setState({ spiner: '' });
    }

    ruDate = (dd) => {
        var now = new Date(dd);
        now = now.toLocaleString();
        return now;
    }
    ruDateLocale = (dd) => {
        var now = new Date(dd);
        now = now.toLocaleDateString();
        return now;
    }
    //Рубли прописью
    // rub =(rub)=>{
    //     var rubles = require('rubles').rubles;
    //     var text = rubles(rub);
    //     if (!text) return text;
    //    text= text[0].toUpperCase() + text.slice(1);
    //     return text;
    // }
    render() {
        return (<>
            {this.state.spiner}
            <Modal show={this.state.show} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Просмотор счетов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <div className="panel">
                            <Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={this.pdfGenerate}>Скачать PDF</Button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="view-id">
                            <div id="content-id" className="content-id">
                            <div className="mt-2 d-flex"><div className="col-header text-start"><cite>Российская Федерация, Московская область, г.Люберцы, ул. Красная  1 лит Р, каб. 115<br /> Tел: +79052222692 <br />Email: team_ff@elida.company </cite>
                                </div><div className="col-header"><img className='elida' src={elida} /></div><div className="col-header text-end"><cite>{this.state.date}<br />{this.state.time}<br />www.elida.company<br />Telegram: @ff_elida</cite></div></div>
                                <Container className="text-center mt-3 mb-2">
                                    <h4> Счет на оплату №: {this.props.id} от {this.ruDate(this.state.data.created_at)}</h4>
                                </Container>
                                <div className="d-flex">
                                    <div className="zakaz">Исполнитель:</div><div className="zakaz2">Компания Elida(ИП Маликов Ш.Н)</div>
                                </div>
                                <div className="d-flex">
                                    <div className="zakaz">Заказчик:</div><div className="zakaz2">{this.state.data.counterparty}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="zakaz">Основание:</div><div className="zakaz2">Окозание услуг</div></div>
                                <div className="line"></div>
                                {
                                    this.state.organizations.map(val => {
                                        var itog = 0;
                                        const entries = (<div key={val.organization_id}>
                                            <div className="d-flex">
                                                <div className="razdel"><small>Подразделение заказчика:</small></div><div className="razdel2"><small><b><i>{val.org_name}</i></b></small></div>
                                            </div>
                                            <Table size="sm">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center text-secondary"><small>Дата</small></th>
                                                        <th className="text-center text-secondary"><small>Заявка</small></th>
                                                        <th className="text-center text-secondary"><small>Изделие</small></th>
                                                        <th className="text-center text-secondary"><small>Услуга</small></th>
                                                        <th className="text-center text-secondary"><small>Кол-во</small></th>
                                                        <th className="text-center text-secondary"><small>Цена</small></th>
                                                        <th className="text-center text-secondary"><small>Сумма</small></th>
                                                        <th className="text-center text-secondary"><small>Примечание</small></th>
                                                        </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.entries.map((a, i) => {
                                                        if (val.organization_id === a.organization_id) {
                                                            itog = itog + a.total_sum;
                                                            const ff = <tr key={i}>
                                                                <td><small><b><i>{this.ruDateLocale(a.public_date)}</i></b></small></td>
                                                                <td className="text-center">{a.application_id}</td>
                                                                <td><small>{a.subject}</small></td>
                                                                <td><small>{a.service}</small></td>
                                                                <td className="text-center">{a.service_count}</td>
                                                                <td className="text-end">{a.service_price}</td>
                                                                <td className="text-end">{a.total_sum}</td>
                                                                <td><small><i>{a.coment}</i></small></td>
                                                                </tr>;
                                                            return ff;
                                                        }
                                                    }
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr><td colSpan={7} className="text-end"><small><i>Промежуточный итог: <b>{itog} руб.</b></i></small></td><td></td></tr>
                                                </tfoot>
                                            </Table>
                                        </div>);
                                        return entries;
                                    })}
                                <div className="d-flex"><div className="footer"><h6>Реквизиты для оплаты</h6>
                                    {/*Сбербанк*/}
                                    <div className="d-flex border-bottom"><div> <img className="bank_logo" src={sber} />
                                    </div><div className="p-1"><cite><b>Шафкат М.</b><br />+7 905 222 26 92   по номеру<br />4817760259027633  по карте</cite></div></div>
                                    <div className="d-flex "><div> <img className="bank_logo" src={raiffeisen} /></div><div className="p-1"><cite><b>Амаль М.</b><br />+7 965 016 66 55   по номеру<br />2200300565085782  по карте</cite></div></div>
                                </div><div className="footer">
                                        <div className="d-flex"><div className="colFoot">Начисленно:</div><div className="colFoot2">{this.state.data.accrual} руб.</div></div>
                                        <div className="d-flex"><div className="colFoot">Задолженость:</div><div className="colFoot2">{this.state.data.balance} руб.</div></div>
                                        <div className="d-flex"><div className="colFoot">Итого к оплате:</div><div className="colFoot2 border-bottom border-dark"><b>{this.state.data.accrual + this.state.data.balance} руб.</b></div></div>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>



        </>);
    }
}
//Квитанция
export class СheckPaymant extends React.Component {
    constructor(props) {
        super(props);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dd = new Date().toLocaleDateString(undefined, options);
        const time = new Date().toLocaleTimeString();
        this.state = { options: {}, show: true, date: dd, time: time, spiner: '', data: [], organizations: [], entries: [] }
    }
    handleClose = () => {
        this.props.closeModal();
    }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email });
        axios.post(DOMEN_SERVER + ACCOUNTING_CHECK_VIEW, { id: this.props.id }).then(res => {
            this.setState({ data: res.data, spiner: '' });
        });
    }
    componentDidUpdate(prevProps, prevState) {

        if (this.props.pdf === true) {
            if (prevState.data != this.state.data) {
                this.pdfGenerate();
                this.props.closeModal();
            }
        }
    }
    //generator PDF
    getTargetElement = () => document.getElementById('content-id');
    pdfGenerate = () => {
        this.setState({ spiner: <Spiner /> });
        var name = this.state.data.counterparty;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        const filename = name + '_принят_платеж_' + this.props.id;
        const options = {
            filename: filename,
            // default is `save`,'open'
            method: 'save',

            resolution: Resolution.LOW,
            page: {
                // margin is in MM, default is Margin.NONE = 0.'SMALL'
                margin: Margin.NONE,
                // default is 'A4','letter'
                format: [65, 110],
                // default is 'portrait','landscape'
                orientation: 'portrait',
            },
            canvas: {
                // default is 'image/jpeg' for better size performance
                mimeType: 'image/png',
                qualityRatio: 1
            },
            // Customize any value passed to the jsPDF instance and html2canvas
            // function. You probably will not need this and things can break, 
            // so use with caution.
            overrides: {
                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                pdf: {
                    compress: true
                },
                // see https://html2canvas.hertzen.com/configuration for more options
                canvas: {
                    useCORS: true
                }
            },
        };

        generatePDF(this.getTargetElement, options);
        this.setState({ spiner: '' });
    }

    ruDate = (dd) => {
        var now = new Date(dd);
        now = now.toLocaleString();
        return now;
    }
    ruDateLocale = (dd) => {
        var now = new Date(dd);
        now = now.toLocaleDateString();
        return now;
    }
    //Рубли прописью
    rub = (rub) => {
        var rubles = require('rubles').rubles;
        var text = rubles(rub);
        if (!text) return text;
        text = text[0].toUpperCase() + text.slice(1);
        return text;
    }
    render() {
        return (<>
            {this.state.spiner}
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Просмотор квитанции</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <div className="panel">
                            <Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={this.pdfGenerate}>Скачать PDF</Button>
                        </div>
                    </div>
                    <div className="view-check d-flex justify-content-center">
                        <div id="content-id" className="content-check">
                            <div className="w-100 d-flex justify-content-center m-1"><img className='elida-check' src={elida} /></div>
                            <div className=" w-100 text-center"><h6>Принят платеж</h6></div>
                            <div className="pko w-100 text-center">Поступление денежных средств №: {this.state.data.cashbox_id} от {this.ruDateLocale(this.state.data.created_at)}</div>
                            <div className="cash-oper w-100 text-start">Принято от :  <span className="text-content">{this.state.data.counterparty}</span></div>
                            <div className="cash-oper w-100 text-start">Основание :  <span className="text-content">{this.state.data.item}</span></div>
                            <div className="cash-oper w-100 text-start">Сумма :  <span className="text-content">{this.state.data.payment} руб.</span></div>
                            <div className="cash-oper w-100 text-start">Прописью :  <span className="text-content">{this.rub(this.state.data.payment)}</span></div>
                            <div className="cash-check-balance w-100 text-center"><b>Текущая задолженость</b></div>
                            <div className="cash-oper w-100 text-start">Сумма :  <span className="text-content">{this.state.data.balance} руб.</span></div>
                            <div className="cash-oper w-100 text-start">Прописью :  <span className="text-content">{this.rub(this.state.data.balance)}</span></div>
                            <img className='oplacheno-check' src={oplacheno} />
                        </div>
                    </div>
                </Modal.Body>

            </Modal>



        </>);
    }
}
//Выставить
export class Bill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '', counterparty_id: '', counterparties: [], show: true, spiner: '', rows: [], col: [], rowModesModel: {}, setRowModesModel: {}, alertSow: false,
            alertMessage: '', total_sum: 0, rowSelectionModel: [], debt: ''
        }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_COUNTERPARTIES).then(res => {
            const counterparties = res.data;
            this.setState({ counterparties: counterparties, spiner: '' });
        });
        this.columns();
    }
    handleClose = () => { this.props.closeModal(); }
    selChange = (event) => {
        this.setState({ spiner: <Spiner />, debt: event.accrual - event.payment });
        axios.post(DOMEN_SERVER + ACCOUNTING_COUNTER_ENTRIES, { id: event.value }).then(res => {
            this.setState({ spiner: '', rows: res.data, counterparty_id: event.value });
        });
    }
    columns = () => {
        const col = [{ field: 'id', headerName: 'ID', width: 60, headerAlign: 'center', align: 'center' },
        { field: 'company', headerName: 'Компания', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'subject', headerName: 'Товар', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'service', headerName: 'Услуга', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'service_count', headerName: 'Кол-во', width: 80, headerAlign: 'center', align: 'right', },
        { field: 'service_price', headerName: 'Цена', width: 80, headerAlign: 'center', align: 'right', },
        { field: 'total_sum', headerName: 'Сумма', width: 120, headerAlign: 'center', align: 'right', },
        { field: 'coment', headerName: 'Примечание', width: 150, headerAlign: 'center', },
        { field: 'public_date', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        ];
        this.setState({ col: col, });
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <Button variant="warning" onClick={this.saveBill}>Сформировать счет</Button>
                <div><cite>Cумма в отмеченных строках: {this.state.total_sum}</cite></div>
            </GridToolbarContainer>
        );
    }
    selForeach = (newRowSelectionModel) => {
        const rows = newRowSelectionModel;
        this.setState({ rowSelectionModel: newRowSelectionModel });
        let total_sum = 0;
        rows.map(val => {
            const sum = this.state.rows.filter((row) => row.id === val);
            total_sum = total_sum + sum[0].total_sum;
        });
        this.setState({ total_sum: total_sum });
    }
    saveBill = () => {
        if (this.state.rowSelectionModel.length > 0) {
            this.setState({ spiner: <Spiner /> });
            let rowsNew = this.state.rows;
            this.state.rowSelectionModel.map(id => { rowsNew = rowsNew.filter((row) => row.id !== id) });
            axios.post(DOMEN_SERVER + ACCOUNTING_CREATE_BILL, {
                email: this.state.email, counterparty_id: this.state.counterparty_id,
                id: this.state.rowSelectionModel, total_sum: this.state.total_sum
            }).then(res => {
                this.props.Invoice(res.data.id);

            });
        } else {
            alert('Не выбрано ни одной строки');
        }
    }
    render() {
        let balance;
        if (this.state.debt != '') {
            balance = <div>Задолженость: <b><u>{this.state.debt}</u></b></div>
        }
        return (<>
            {this.state.spiner}
            <Modal show={this.state.show} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Выставить счет</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-1"><Select options={this.state.counterparties} placeholder="Заказчики" onChange={this.selChange} /> </div>
                    {balance}
                    <div>
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                                editMode="row"
                                slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                checkboxSelection
                                disableRowSelectionOnClick
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    this.selForeach(newRowSelectionModel);
                                }}
                                rowSelectionModel={this.state.rowSelectionModel}
                            />
                        </Box>
                    </div>
                </Modal.Body>
            </Modal>
        </>);
    }
}
//Проводки
export class Entries extends React.Component {
    constructor(props) {
        super(props);
        this.state = { col: [], rows: [], modal: [], spiner: '', rowModesModel: {}, setRowModesModel: {}, rowSelectionModel: [], total_sum: 0 }

    }
    addEntries = () => {

        this.setState({ modal: <AddEntriesModal closeModal={this.closeModal} updatedRow={this.updatedRow} /> });
    }
    reportApplications = () => {
        this.setState({ modal: <ReportApplication closeModal={this.closeModal} /> });
    }
    newBill = () => {
        this.setState({ modal: <Bill closeModal={this.closeModal} Invoice={this.Invoice} /> });
    }
    Invoice = (id) => {
        this.setState({ modal: <Invoice closeModal={this.closeModal} id={id} /> });
    }
    accountsModal = () => {
        this.setState({ modal: <AccountsModal closeModal={this.closeModal} /> });
    }
    cashboxModal = () => {
        this.setState({ modal: <AcceptPaymentModal closeModal={this.closeModal} updatedRow={this.updatedRow} /> });
    }
    closeModal = () => {
        this.setState({ modal: '' });
        this.rows();
    }
    updatedRow = () => {
        this.rows();
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState(val => ({ spiner: <Spiner />, email: store.email }));
        this.columns();
        this.rows();
        this.setState({ spiner: '' });
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'ActiveEntries',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                <div><cite>Cумма в отмеченных строках: {this.state.total_sum}</cite></div>
            </GridToolbarContainer>
        );
    }
    processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        const upRows = this.state.rows.map((row) => (row.id === newRow.id ? updatedRow : row));
        this.setState({ rows: upRows });

        const id = updatedRow.id;
        const service_count = updatedRow.service_count;
        const service_price = updatedRow.service_price;
        const coment = updatedRow.coment;
        const D = new Date(updatedRow.public_date);
        D.setDate(D.getDate() + 1);
        const public_date = D.toJSON().slice(0, 10);
        axios.post(DOMEN_SERVER + ACCOUNTING_ENTRIES_UPDATE, { email: this.state.email, id: id, service_count: service_count, service_price: service_price, coment: coment, public_date: public_date });
        this.selForeach(this.state.rowSelectionModel);
        return updatedRow;
    }
    handleProcessRowUpdateError = (error) => {
        console.log(error);
    }
    handleRowModesModelChange = (newRowModesModel) => {
        //console.log(newRowModesModel); не понятный
        this.setState({ setRowModesModel: newRowModesModel });
    };
    handleSaveClick = (id) => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View } }) });
    };
    handleCancelClick = (id) => {
        this.setState.rowModesModel = {};
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.View, ignoreModifications: true } }) });
        const editedRow = this.state.rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            this.state.rows.filter((row) => row.id !== id);
        }
    };
    handleEditClick = (id) => {
        this.setState({ rowModesModel: ({ [id]: { mode: GridRowModes.Edit } }) });
    }
    handleDeleteClick = (id) => {
        this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
        axios.post(DOMEN_SERVER + ACCOUNTING_ENTRIES_DELETE, { email: this.state.email, id: id });
    }
    rows = () => {

        axios.post(DOMEN_SERVER + ACCOUNTING_ENTRIES_LIST).then(res => {
            this.setState({ rows: res.data });
        });
    }
    columns = () => {
        const col = [{ field: 'id', headerName: 'ID', width: 60, headerAlign: 'center', align: 'center' },
        { field: 'application_id', headerName: 'Заявка', width: 100, headerAlign: 'center', align: 'center' },
        { field: 'counterparty', headerName: 'Агент', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'company', headerName: 'Компания', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'subject', headerName: 'Товар', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'service', headerName: 'Услуга', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'service_count', headerName: 'Кол-во', width: 80, headerAlign: 'center', align: 'right', editable: true, },
        { field: 'service_price', headerName: 'Цена', width: 80, headerAlign: 'center', align: 'right', editable: true, },
        { field: 'total_sum', headerName: 'Сумма', width: 120, headerAlign: 'center', align: 'right', },
        { field: 'coment', headerName: 'Примечание', width: 150, headerAlign: 'center', editable: true, },
        { field: 'public_date', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), editable: true, type: 'date' },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={() => this.handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={() => this.handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => this.handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={() => this.handleDeleteClick(id)}
                        color="inherit"
                    />,

                ];
            },

        },
        ];
        this.setState({ col: col, });
    }
    selForeach = (newRowSelectionModel) => {
        const rows = newRowSelectionModel;
        this.setState({ rowSelectionModel: newRowSelectionModel });
        let total_sum = 0;
        rows.map(val => {
            const sum = this.state.rows.filter((row) => row.id === val);
            total_sum = total_sum + (sum[0].total_sum);
        });
        this.setState({ total_sum: total_sum });
    }
    render() {

        return (<div className=" col-11">
            {this.state.spiner}
            {<AddEntries updatedRow={this.updatedRow} />}
            <div><h5>Проводки</h5></div>
            <div className="d-flex">
                <div>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={this.addEntries}>Добавить</Button>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={this.reportApplications}>Заявки</Button>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="warning" onClick={this.newBill}>Выставить</Button>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="primary" onClick={this.accountsModal}>Счета</Button>
                    <Button className="ms-2 mb-2 mt-1" size="sm" variant="success" onClick={this.cashboxModal}>Оплата</Button>
                </div>
            </div>
            <div >
                {this.state.modal}
            </div>
            <div >
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        editMode="row"
                        slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        processRowUpdate={this.processRowUpdate}
                        onProcessRowUpdateError={this.handleProcessRowUpdateError}
                        rowModesModel={this.state.rowModesModel}
                        onRowModesModelChange={this.handleRowModesModelChange}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            this.selForeach(newRowSelectionModel);
                        }}
                        rowSelectionModel={this.state.rowSelectionModel}
                    />
                </Box>
            </div>
        </div>);
    }
}
//Принять оплату окно
export class AcceptPaymentModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cash: [], cash_id: 0, radio: [], counterparties: [], spiner: '', email: '', counterparty_id: '', sumpay: '', item: '', debt: '', description: '', alertSow: false, alertMessage: '', }
        this.inputChange = this.inputChange.bind(this);
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_GROUP_ITEMS, { group: 'cash_receipt' }).then(res => {
            this.setState({ radio: res.data, spiner: '' });
        });
        axios.post(DOMEN_SERVER + ACCOUNTING_CASH_SELECT).then(res => {
            this.setState({ cash: res.data, spiner: '' });
        });
        this.counter();
    }
    counter = () => {
        axios.post(DOMEN_SERVER + ACCOUNTING_COUNTERPARTIES).then(res => {
            this.setState({ counterparties: res.data });
        });
    }
    selChange = (event) => {
        this.setState({ counterparty_id: event.value, debt: event.accrual - event.payment });
    }
    selChangeCash = (event) => {
        this.setState({ cash_id: event.value });
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleClose = () => { this.props.closeModal(); }
    radioClick = (event) => {
        this.setState({ item: event.target.id });
    }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    insertCashbox = () => {
        if (this.state.counterparty_id > 0 && this.state.item > 0 && this.state.sumpay > 0 && this.state.cash_id > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_ACCEPT_PAYMENT, {
                email: this.state.email,
                counterparty_id: this.state.counterparty_id, item: this.state.item, payment: this.state.sumpay,
                description: this.state.description, cash_id: this.state.cash_id
            }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.setState({ sumpay: '' });
                    this.counter();
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let control = '';
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        let balance;
        if (this.state.debt != '') {
            balance = <div>Задолженость: <b><u>{this.state.debt}</u></b></div>
        }
        return (<>
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Принять оплату</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-1"><Select options={this.state.cash} placeholder="Касса" onChange={this.selChangeCash} /> </div>
                    <div className="mb-1"><Select options={this.state.counterparties} placeholder="Контрагенты" onChange={this.selChange} /> </div>
                    {balance}
                    <Form>
                        {this.state.radio.map((a, i) => (<div key={i}><Form.Check
                            inline
                            label={a.name}
                            name="group1"
                            type='radio'
                            id={a.id}
                            onClick={this.radioClick} /></div>))}
                        <Form.Control type="number" placeholder="Сумма" name="sumpay" className="mt-2 text-end" value={this.state.sumpay} onChange={this.inputChange} />
                        <Form.Control as="textarea" rows={3} placeholder="Примечание" className="mt-2 " name="description" value={this.state.description} onChange={this.inputChange} />

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={this.handleClose} >Отмена</Button>
                    <Button variant="success" size='sm' onClick={this.insertCashbox} >Сохранить</Button>
                </Modal.Footer>
                {message}
            </Modal>
        </>);
    }
}
//Оплатиь расход окно
export class PayExpenseModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cash: [], cash_id: 0, radio: [], counterparties: [], spiner: '', email: '', counterparty_id: '', sumpay: '', item: '', debt: '', description: '', alertSow: false, alertMessage: '', }
        this.inputChange = this.inputChange.bind(this);
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_GROUP_ITEMS, { group: 'cash_expense' }).then(res => {
            this.setState({ radio: res.data, spiner: '' });
        });
        axios.post(DOMEN_SERVER + ACCOUNTING_CASH_SELECT).then(res => {
            this.setState({ cash: res.data, spiner: '' });
        });
        this.counter();
    }
    counter = () => {
        axios.post(DOMEN_SERVER + ACCOUNTING_COUNTERPARTIES).then(res => {
            this.setState({ counterparties: res.data });
        });
    }
    selChange = (event) => {
        this.setState({ counterparty_id: event.value, debt: event.accrual - event.payment });
    }
    selChangeCash = (event) => {
        this.setState({ cash_id: event.value });
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleClose = () => { this.props.closeModal(); }
    radioClick = (event) => {
        this.setState({ item: event.target.id });
    }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    insertCashbox = () => {
        if (this.state.counterparty_id > 0 && this.state.item > 0 && this.state.sumpay > 0 && this.state.cash_id > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_PAY_EXPENSE, { email: this.state.email, counterparty_id: this.state.counterparty_id, item: this.state.item, payment: this.state.sumpay, description: this.state.description, cash_id: this.state.cash_id }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.setState({ sumpay: '' });
                    this.counter();
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let control = '';
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        let balance;
        if (this.state.debt != '') {
            balance = <div>Задолженость: <b><u>{this.state.debt}</u></b></div>
        }
        return (<>
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Оплатить расходы</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-1"><Select options={this.state.cash} placeholder="Касса" onChange={this.selChangeCash} /> </div>
                    <div className="mb-1"><Select options={this.state.counterparties} placeholder="Контрагенты" onChange={this.selChange} /> </div>
                    {balance}
                    <Form>
                        {this.state.radio.map((a, i) => (<div key={i}><Form.Check
                            inline
                            label={a.name}
                            name="group1"
                            type='radio'
                            id={a.id}
                            onClick={this.radioClick} /></div>))}
                        <Form.Control type="number" placeholder="Сумма" name="sumpay" className="mt-2 text-end" value={this.state.sumpay} onChange={this.inputChange} />
                        <Form.Control as="textarea" rows={3} placeholder="Примечание" className="mt-2 " name="description" value={this.state.description} onChange={this.inputChange} />

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={this.handleClose} >Отмена</Button>
                    <Button variant="danger" size='sm' onClick={this.insertCashbox} >Сохранить</Button>
                </Modal.Footer>
                {message}
            </Modal>
        </>);
    }
}
//Выплата дидвидентов
export class DividentsExpense extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shareholders : [], share_id:0,  cash: [], cash_id: 0, spiner: '', email: '', sumpay: '', description: '', alertSow: false, alertMessage: '', }
        this.inputChange = this.inputChange.bind(this);
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_CASH_SELECT).then(res => {
            this.setState({ cash: res.data, spiner: '' });
        });
        this.shareholders();

    }
    shareholders = () => {
        axios.post(DOMEN_SERVER + ACCOUNTING_SHAREHOLDERS).then(res => {
            this.setState({ shareholders: res.data });
        });
    }
    shareChange = (event) => {
        this.setState({ share_id: event.value, description: event.label});
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    selChangeCash = (event) => {
        this.setState({ cash_id: event.value });
    }
    handleClose = () => { this.props.closeModal(); }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    insertCashbox = () => {
        if (this.state.share_id > 0 && this.state.sumpay > 0 && this.state.cash_id > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_DIVIDEND, { email: this.state.email, id: this.state.share_id, item: 9, sum: this.state.sumpay, description: this.state.description, cash_id: this.state.cash_id }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.setState({ sumpay: '', description: '' });
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        return (<>
            {this.state.spiner}
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Выплата дивидентов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="mb-1"><Select options={this.state.cash} placeholder="Касса" onChange={this.selChangeCash} /> </div>
                        <div className="mb-1"><Select options={this.state.shareholders} placeholder="Дольщик" onChange={this.shareChange} /> </div>
                        <Form.Control type="number" placeholder="Сумма" name="sumpay" className="mt-2 text-end" value={this.state.sumpay} onChange={this.inputChange} />
                        <Form.Control as="textarea" rows={3} placeholder="Примечание" className="mt-2 " name="description" value={this.state.description} onChange={this.inputChange} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={this.handleClose} >Отмена</Button>
                    <Button variant="danger" size='sm' onClick={this.insertCashbox} >Сохранить</Button>
                </Modal.Footer>
                {message}
            </Modal>
        </>);
    }
}

//Внутренний перевод
export class LocalTransfer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cash: [], cash_id: 0, cash_id2: 0, spiner: '', email: '', sumpay: '', description: '', alertSow: false, alertMessage: '', }
        this.inputChange = this.inputChange.bind(this);
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_CASH_SELECT).then(res => {
            this.setState({ cash: res.data, spiner: '' });
        });

    }

    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    selChangeCash = (event) => {
        if (this.state.cash_id2 !== event.value) {
            this.setState({ cash_id: event.value });
        } else {
            alert('Выберие разные кассы');
            this.setState({ cash_id: 0 });
        }
    }
    selChangeCash2 = (event) => {
        if (this.state.cash_id !== event.value) {
            this.setState({ cash_id2: event.value });
        } else {
            alert('Выберие разные кассы');
            this.setState({ cash_id2: 0 });
        }

    }
    handleClose = () => { this.props.closeModal(); }
    message = (a) => {
        let alert = (
            <Alert variant={a.variant} onClose={() => this.setState({ alertSow: false })} dismissible>
                <cite>{a.message}</cite>
            </Alert>);
        this.setState({ alertMessage: alert, alertSow: true });
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            this.setState({ alertSow: false })
        }, 3000);
    }
    insertCashbox = () => {
        if (this.state.sumpay > 0 && this.state.cash_id > 0 && this.state.cash_id2 > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_LOCALE_TRANSFER, { email: this.state.email, counterparty_id: 0, payment: this.state.sumpay, description: this.state.description, cash_id: this.state.cash_id, cash_id2: this.state.cash_id2 }).then(res => {
                if (res.data.status === true) {
                    this.message({ variant: 'success', message: res.data.message });
                    this.setState({ sumpay: '', description: '' });
                    this.props.updatedRow();
                } else {
                    this.message({ variant: 'danger', message: res.data.message });
                }
            });
        } else {
            this.message({ variant: 'danger', message: 'Не указаны значения' });
        }
    }
    render() {
        let message = '';
        if (this.state.alertSow === true) {
            message = this.state.alertMessage;
        }
        return (<>
            {this.state.spiner}
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Перевод между кассами</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form><div>из</div>
                        <div className="mb-1"><Select options={this.state.cash} placeholder="Касса" onChange={this.selChangeCash} /> </div>
                        <div>в</div>
                        <div className="mb-1"><Select options={this.state.cash} placeholder="Касса" onChange={this.selChangeCash2} /> </div>
                        <Form.Control type="number" placeholder="Сумма" name="sumpay" className="mt-2 text-end" value={this.state.sumpay} onChange={this.inputChange} />
                        <Form.Control as="textarea" rows={3} placeholder="Примечание" className="mt-2 " name="description" value={this.state.description} onChange={this.inputChange} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={this.handleClose} >Отмена</Button>
                    <Button variant="danger" size='sm' onClick={this.insertCashbox} >Сохранить</Button>
                </Modal.Footer>
                {message}
            </Modal>
        </>);
    }
}
//основание
export class ItemsModal extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'name', headerName: 'Название', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'key', headerName: 'Ключ', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'group', headerName: 'Группа', width: 150, headerAlign: 'center', align: 'center' },];
        this.state = { name: '', key: '', group: '', rows: [], col: col, spiner: '', table: '' };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleClose = () => { this.props.closeModal(); }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ACCOUNTING_ITEMS).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
        this.table();
    }
    add_item = () => {
        if (this.state.name != '') {
            axios.post(DOMEN_SERVER + ACCOUNTING_ADD_ITEM, { name: this.state.name, key: this.state.key, group: this.state.group }).then(res => {
                this.setState({ rows: res.data, name: '', group: '', key: '' });
            });
        } else {
            alert("Введите название");
        }
    }
    componentDidUpdate(prevProps, prevState) {


        if (prevState.rows != this.state.rows) {
            this.table();
        }
    }
    table = () => {
        let table = <Box sx={{ width: '100%', }}>
            <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>;
        this.setState({ table: table });
    }
    render() {

        return (<>
            <Modal show={true} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Настройка элементов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form className=" mb-3 d-flex ">
                            <Form.Control id="1" type="text" className="m-2" placeholder="Название" name="name" value={this.state.name} onChange={this.inputChange} />
                            <Form.Control id="2" type="text" className="m-2" placeholder="Ключ" name="key" value={this.state.key} onChange={this.inputChange} />
                            <Form.Control id="3" type="text" className="m-2" placeholder="Группа" name="group" value={this.state.group} onChange={this.inputChange} />
                            <Button variant="success" className="m-2" size="sm" onClick={this.add_item}> Добавить</Button>
                        </Form>
                        {this.state.table}
                    </div>

                </Modal.Body>
            </Modal>
        </>)
    }
}
//Счет окно
export class AccountsModal extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClose = () => { this.props.closeModal(); }
    render() {
        return (<>
            <Modal show={true} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Счета</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accounts />
                </Modal.Body>
            </Modal>
        </>)
    }
}
//Счета
export class Accounts extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'public_date', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'id', headerName: '№', width: 60, headerAlign: 'center', align: 'center' },
        { field: 'description', headerName: 'Документ', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'accrual', headerName: 'Начисление', width: 120, headerAlign: 'center', cellClassName: (params) => { if (params.value > 0) { return 'super-app-theme-accrual'; } }, align: 'center' },
        { field: 'payment', headerName: 'Оплата', width: 120, headerAlign: 'center', cellClassName: (params) => { if (params.value > 0) { return 'super-app-theme-payment'; } }, align: 'center' },
        { field: 'user', headerName: 'Исполнитель', width: 120, headerAlign: 'center', align: 'center' },
        {
            field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Аннулировать"
                        onClick={() => this.handleDeleteClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<VisibilityIcon />}
                        label="Просмотр"
                        className="textPrimary"
                        onClick={() => this.handlVisibilClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<PictureAsPdfIcon />}
                        label="Печать"
                        className="textPrimary"
                        onClick={() => this.handlPrintClick(id)}
                        color="inherit"
                    />,

                ];
            },

        }
        ];
        this.state = { email: '', spiner: '', counterparty_name: '', counterparties: [], rows: [], col: col, modal: '', counterparty_id: '', balance: '', }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + MANEGMENT_COUNTERPARTIES).then(res => {
            this.setState({ counterparties: res.data, spiner: '' });
        });
    }

    selChange = (event) => {
        var name = event.label;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        this.setState({ spiner: <Spiner />, counterparty_id: event.value, counterparty_name: name });
        this.rows(event.value);
    }
    rows = (id) => {
        axios.post(DOMEN_SERVER + ACCOUNTING_COUN_JOURNAL, { id: id }).then(res => {
            this.setState({ spiner: '', rows: res.data.rows, balance: res.data.balance });
        });
    }
    handleDeleteClick = (id) => {
        axios.post(DOMEN_SERVER + ACCOUNTING_DELETE_INVOICE, { email: this.state.email, id: id });
        this.rows(this.state.counterparty_id);
    }
    handlVisibilClick = (id) => {
        const rows = this.state.rows.filter((row) => row.id == id);
        if (rows[0].description == 'Счет на оплату') {
            this.setState({ modal: <Invoice closeModal={this.closeModal} id={id} /> });
        }
        if (rows[0].description == 'Аннулирован') {
            this.setState({ modal: <AnnulatedDocument closeModal={this.closeModal} id={id} /> });
        }
        if (rows[0].description == 'Принят платеж') {
            this.setState({ modal: <СheckPaymant closeModal={this.closeModal} id={id} /> });
        }

    }
    handlPrintClick = (id) => {
        const rows = this.state.rows.filter((row) => row.id == id);
        if (rows[0].description == 'Счет на оплату') {
            this.setState({ modal: <Invoice closeModal={this.closeModal} pdf={true} id={id} /> });
        }
        if (rows[0].description == 'Аннулирован') {
            this.setState({ modal: <AnnulatedDocument closeModal={this.closeModal} id={id} /> });
        }
        if (rows[0].description == 'Принят платеж') {
            this.setState({ modal: <СheckPaymant closeModal={this.closeModal} pdf={true} id={id} /> });
        }

    }
    closeModal = () => {
        this.setState({ modal: '' });
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Move_' + this.state.counterparty_name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }
    render() {

        let table = '';
        if (this.state.rows.length > 0) {
            table = <div> <div>Задолженость {this.state.balance}</div>
                <Box
                    sx={{
                        width: '100%',
                        '& .super-app-theme-accrual': {
                            backgroundColor: '#CCFFFF',
                            color: '#1a3e72',
                            fontWeight: '600',

                        },
                        '& .super-app-theme-payment': {
                            backgroundColor: '#5FF249',
                            color: '#1a3e72',
                            fontWeight: '600',

                        },
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box></div>;
        }
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };

        return (<div className="col-11">
            {this.state.modal}
            {this.state.spiner}
            <h6>Счета</h6>
            <div className="mb-2 mt-3 w-100 text-left"><Select options={this.state.counterparties} styles={customStyles} placeholder="Контрагенты" onChange={this.selChange} /> </div>
            {table}
        </div>);
    }
}
//Аннулированный документ
export class AnnulatedDocument extends React.Component {
    constructor(props) {
        super(props);
    }
    handleClose = () => { this.props.closeModal(); }
    render() {
        return (<>
            <Modal show={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> Документ №: {this.props.id} - Аннулирован!</Modal.Title>
                </Modal.Header>
            </Modal>
        </>);
    }
}
//Зарплата
export class Salary extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'created_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'id', headerName: '№', width: 60, headerAlign: 'center', align: 'center' },
        { field: 'personal', headerName: 'Имя', width: 150, headerAlign: 'center', align: 'center' },
        { field: 'accrued', headerName: 'Начисление', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'held', headerName: 'Удержано', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'paid', headerName: 'Оплата', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'balance', headerName: 'Баланс', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'description', headerName: 'Примечание', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'user', headerName: 'Провел', width: 120, headerAlign: 'center', align: 'center' },
        ];
        this.state = {
            cash: [], cash_id: 0,
            email: '', spiner: '', rows: [], personal: [], personal_id: '', col: col, modal: '',
            counterparty_id: '', oklad: 0, paid: 0, held: 0, accruad: 0, description: '', accruedT: 0, heldT: 0, paidT: 0
        }
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_PERSONAL).then(res => {
            this.setState({ personal: res.data, spiner: '' });
        });
        axios.post(DOMEN_SERVER + ACCOUNTING_CASH_SELECT).then(res => {
            this.setState({ cash: res.data, spiner: '' });
        });
        this.rows();
    }
    selChange = (event) => {
        this.setState({ personal_id: event.value, oklad: event.oklad, paid: 0, held: 0, accruad: 0, description: '' });
    }
    selChangeCash = (event) => {
        this.setState({ cash_id: event.value });
    }
    rows = () => {
        axios.post(DOMEN_SERVER + ACCOUNTING_SALARY_TOP).then(res => {
            this.setState({ rows: res.data.salaries, accruedT: res.data.accrued, heldT: res.data.held, paidT: res.data.paid });
        })
    }
    oklad = () => {
        if (this.state.oklad > 0) {
            this.setState({ accruad: this.state.oklad, description: 'Оклад' });
        }
    }
    insertSalary = () => {
        if (this.state.paid > 0 && this.state.cash_id === 0) {
            alert('Укажите кассу');
            return;
        }
        const operSum = this.state.accruad + this.state.held + this.state.paid;
        if (operSum > 0 && this.state.personal_id > 0) {

            axios.post(DOMEN_SERVER + ACCOUNTING_ADD_SALARY, { email: this.state.email, personal_id: this.state.personal_id, accrued: this.state.accruad, held: this.state.held, paid: this.state.paid, description: this.state.description, cash_id: this.state.cash_id }).then(res => {
                this.rows();
                this.setState({ paid: 0, held: 0, accruad: 0, description: '' });
            });
        }

    }
    salaryCalculation = () => {
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_SALARY_CALCULATION, { email: this.state.email }).then(res => {
            this.setState({ spiner: '' })
            this.rows();
        });
    }
    personalListId = () => {
        if (this.state.personal_id > 0) {
            this.setState({ modal: <PersonalListIdModal id={this.state.personal_id} closeModal={this.closeModal} /> });
        } else {
            alert('Выберите сотрудника');
        }
    }
    personalList = () => {
        this.setState({ modal: <PersonalList closeModal={this.closeModal} /> });
    }
    closeModal = () => {
        this.setState({ modal: '' });
    }
    CustomToolbar = (props) => {
        var formatter = new Intl.NumberFormat('ru');
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'ELIDA_SALARY',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                <div>Всего: Начислено: <u>{formatter.format(this.state.accruedT)}</u> - Удержано: <u>{formatter.format(this.state.heldT)}</u> - Оплачено:<u>{formatter.format(this.state.paidT)}</u> = Задолженость: <u>{formatter.format(this.state.accruedT - this.state.heldT - this.state.paidT)}</u></div>
            </GridToolbarContainer>
        );
    }
    render() {
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        return (<>
            {this.state.spiner}
            {this.state.modal}
            <h6>Зарплата</h6>
            <div className="p-5  w-100">
                <div className="d-flex p-2">
                    <Button variant="warning" className="m-1" size="sm" onClick={this.oklad}>Оклад</Button>
                    <Button variant="warning" className="m-1" size="sm" onClick={this.salaryCalculation}>Авто-просчет</Button>
                    <Button variant="success" className="m-1" size="sm" onClick={this.personalListId}>Персональный</Button>
                    <Button variant="success" className="m-1" size="sm" onClick={this.personalList}>Список</Button>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-8 col-sm-10"><Select options={this.state.personal} placeholder="Список сотрудников" className="m-1" styles={customStyles} onChange={this.selChange} /><Select options={this.state.cash} className="m-1" placeholder="Касса" onChange={this.selChangeCash} /> </div>
                <div className="d-flex w-100">
                    <div className="m-1">
                        <Form.Label>Начислить</Form.Label>
                        <Form.Control type="number" name="accruad" className="text-end" value={this.state.accruad} placeholder="Начислить" onChange={this.inputChange} />
                    </div>
                    <div className="m-1">
                        <Form.Label>Удержать</Form.Label>
                        <Form.Control type="number" name="held" className="text-end" value={this.state.held} placeholder="Удержать" onChange={this.inputChange} />
                    </div>
                    <div className="m-1">
                        <Form.Label>Оплачено</Form.Label>
                        <Form.Control type="number" name="paid" className="text-end" value={this.state.paid} placeholder="Оплачено" onChange={this.inputChange} />
                    </div>
                </div>
                <div className="d-flex w-100">
                    <div className="m-1">
                        <Form.Control type="text" name="description" row={3} value={this.state.description} placeholder="Примечание" onChange={this.inputChange} />
                    </div>
                    <Button variant="primary" className="m-1" size="sm" onClick={this.insertSalary}>Сохранить</Button>
                </div>
            </div>
            <div className="w-100 p-4">
                <Box sx={{ width: '100%', }}>
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    />
                </Box>;
            </div>
        </>);
    }
}
//Зарплата персональный
export class PersonalListId extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'created_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'id', headerName: '№', width: 60, headerAlign: 'center', align: 'center' },
        { field: 'accrued', headerName: 'Начисление', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'held', headerName: 'Удержано', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'paid', headerName: 'Оплата', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'balance', headerName: 'Баланс', width: 120, headerAlign: 'center', align: 'center' },
        { field: 'description', headerName: 'Примечание', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'user', headerName: 'Провел', width: 120, headerAlign: 'center', align: 'center' },
        ];
        this.state = { rows: [], col: col, spiner: '' };
    }
    handleClose = () => { this.props.closeModal(); }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        if (this.props.id) {
            axios.post(DOMEN_SERVER + ACCOUNTING_PERSONAL_LIST_ID, { personal_id: this.props.id }).then(res => {
                this.setState({ rows: res.data.rows, spiner: '' });
                if (this.props.personal) {
                    this.props.personal(res.data.personal);
                }
            });
        }
        if (this.props.email) {
            axios.post(DOMEN_SERVER + ACCOUNTING_PERSONAL_LIST_ID, { email: this.props.email }).then(res => {
                this.setState({ rows: res.data.rows, spiner: '' });
                if (this.props.personal) {
                    this.props.personal(res.data.personal);
                }
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.rows != this.state.rows) {
            this.table();
        }
    }
    table = () => {
        let table = <Box sx={{
            width: '100%',
            '& .super-app-theme-accrued': {
                backgroundColor: '#CAFCFF',

            },
            '& .super-app-theme-paid': {
                backgroundColor: '#CAFFCC',

            },
            '& .super-app-theme-held': {
                backgroundColor: '#FFD7CA',

            },
        }}>
            <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => {
                    if (params.row.accrued > 0) { return 'super-app-theme-accrued'; }
                    if (params.row.paid > 0) { return 'super-app-theme-paid'; }
                    if (params.row.held > 0) { return 'super-app-theme-held'; }
                }}
            />
        </Box>;
        this.setState({ table: table });
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'ELIDA_SALARY',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }
    render() {

        return (<>

            <div>
                {this.state.table}
            </div>
        </>)
    }
}
export class PersonalListIdModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { personal: '' };
    }
    handleClose = () => { this.props.closeModal(); }
    personal = (personal) => {
        this.setState({ personal: personal });
    }
    render() {

        return (<>
            <Modal show={true} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Отчет по зарплате {this.state.personal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PersonalListId id={this.props.id} personal={this.personal} />
                </Modal.Body>
            </Modal>
        </>)
    }
}
//Зарплата Список
export class PersonalList extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'id', headerName: 'ID', width: 60, headerAlign: 'center', },
            { field: 'name', align: 'center', headerName: 'Сотрудник', width: 200, headerAlign: 'center', },
            { field: 'accrued', align: 'center', headerName: 'Начисление', width: 150, headerAlign: 'center', },
            { field: 'held', align: 'center', headerName: 'Удержано', width: 150, headerAlign: 'center', },
            { field: 'paid', align: 'center', headerName: 'Оплата', width: 150, headerAlign: 'center', },
            { field: 'balance', align: 'center', headerName: 'Баланс', width: 150, headerAlign: 'center', valueGetter: (params) => { return parseFloat(params.row.accrued - params.row.held - params.row.paid).toFixed(2) }, },
        ];
        this.state = { rows: [], col: col, spiner: '', personal: '' };
    }
    handleClose = () => { this.props.closeModal(); }
    componentDidMount() {
        this.setState({ spiner: <Spiner /> });
        axios.post(DOMEN_SERVER + ACCOUNTING_PERSONAL_LIST).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
        this.table();
    }
    componentDidUpdate(prevProps, prevState) {


        if (prevState.rows != this.state.rows) {
            this.table();
        }
    }
    table = () => {
        let table = <Box sx={{ width: '100%', }}>
            <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                slots={{ toolbar: this.CustomToolbar }} localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        </Box>;
        this.setState({ table: table });
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Зарплата_список',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
            </GridToolbarContainer>
        );
    }
    render() {

        return (<>
            <Modal show={true} fullscreen={true} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Отчет по зарплате список</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {this.state.table}
                    </div>

                </Modal.Body>
            </Modal>
        </>)
    }
}
//Kacca
export class Cashbox extends React.Component {
    constructor(props) {
        super(props);
        const col = [{ field: 'id', headerName: '№', width: 60, headerAlign: 'center', },
        { field: 'created_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
        { field: 'agent', headerName: 'Агент', width: 150, headerAlign: 'center', },
        { field: 'item', headerName: 'Основание', width: 150, headerAlign: 'center', },
        { field: 'incoming', align: 'right', headerName: 'Принято', width: 120, headerAlign: 'center', },
        { field: 'expense', align: 'right', headerName: 'Оплачено', width: 120, headerAlign: 'center', },
        { field: 'description', align: 'center', headerName: 'Примечание', width: 150, headerAlign: 'center', },
        { field: 'cash', align: 'center', headerName: 'Касса', width: 150, headerAlign: 'center', },
        { field: 'user', headerName: 'Провел', align: 'center', width: 150, headerAlign: 'center', },
        ];
        this.state = { modal: '', rows: [], col: col, cashes_balance: [], balance: 0 }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.rows();
    }
    acceptPaymentModal = () => {
        this.setState({ modal: <AcceptPaymentModal updatedRow={this.updatedRow} closeModal={this.closeModal} /> });
    }
    payExpenseModal = () => {
        this.setState({ modal: <PayExpenseModal updatedRow={this.updatedRow} closeModal={this.closeModal} /> });
    }
    closeModal = () => {
        this.setState({ modal: '' });
        this.rows();
    }
    updatedRow = () => {
        this.rows();
    }
    rows = () => {
        axios.post(DOMEN_SERVER + ACCOUNTING_CASHBOX_TOP).then(res => {
            this.setState({ balance: res.data.balance, rows: res.data.cashboxes, cashes_balance: res.data.cashes_balance });
        });
    }
    CustomToolbar = (props) => {
        var formatter = new Intl.NumberFormat('ru');
        return (
            <GridToolbarContainer>

                <div className="text-start"><GridToolbarExport
                    csvOptions={{
                        fileName: 'Касса_движение',
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                /><h5>Баланс: {this.state.balance} руб.</h5>{this.state.cashes_balance.map((val, i) => (<h6 key={i}>{val.name + ': ' + val.balance} руб.</h6>))}</div>

            </GridToolbarContainer>
        );
    }
    dividends = () => {
        this.setState({ modal: <DividentsExpense updatedRow={this.updatedRow} closeModal={this.closeModal} /> });
    }
    localTransfer = () => {
        this.setState({ modal: <LocalTransfer updatedRow={this.updatedRow} closeModal={this.closeModal} /> });
    }
    render() {
        return (<>
            {this.state.modal}
            <h4>Касса</h4>
            <div className="w-100 d-flex p-4">
                <Button variant="success" className="m-1" onClick={this.acceptPaymentModal} >Приход</Button>
                <Button variant="danger" className="m-1" onClick={this.payExpenseModal}>Расход</Button>
                <Button variant="warning" className="m-1" onClick={this.dividends}>Дивиденды</Button>
                <Button variant="secondary" className="m-1" onClick={this.localTransfer}>Перевод</Button>
            </div>
            <div className="w-100 p-4">
                <Box sx={{
                    width: '100%',
                    '& .super-app-theme-incoming': {
                        backgroundColor: '#A5FF84',

                    },
                    '& .super-app-theme-expense': {
                        backgroundColor: '#FF7A7A',

                    },
                }}>
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        slots={{ toolbar: this.CustomToolbar }}
                        getRowClassName={(params) => {
                            if (params.row.incoming > 0) { return 'super-app-theme-incoming'; }
                            if (params.row.expense > 0) { return 'super-app-theme-expense'; }
                        }}
                    />
                </Box>;
            </div>
        </>);
    }
}
//Договора
export class Agreement extends React.Component {
    constructor(props) {
        super(props);
        const customStyles = {
            option: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            control: (defaultStyles) => ({
                ...defaultStyles,
                textAlign: 'left',
            }),
            singleValue: (defaultStyles) => ({ ...defaultStyles, }),
        };
        const col = [
            { field: 'updated_at', headerName: 'Дата', width: 120, headerAlign: 'center', valueGetter: (params) => new Date(params.value), type: 'date' },
            { field: 'service', headerName: 'Сервис', width: 300, headerAlign: 'center', align: 'left' },
            { field: 'price', headerName: 'Цена', width: 150, headerAlign: 'center', align: 'center' },
            { field: 'user', headerName: 'Обновил', width: 200, headerAlign: 'center', align: 'center' },
            {
                field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                    const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => this.handleDeleteClick(id)}
                            color="inherit"
                        />,

                    ];
                },

            },
        ];
        this.state = { email: '', modal: '', counterparty_name: '', con_n: '', counterparties: [], servicies: [], rowModesModel: {}, rows: [], col: col, customStyles: customStyles, counterparty_id: 0, service_id: 0, price: 0 };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + MANEGMENT_COUNTERPARTIES).then(res => {
            this.setState({ counterparties: res.data });
        });
        axios.post(DOMEN_SERVER + ACCOUNTING_SERVICE_AGR).then(res => {
            this.setState({ servicies: res.data, spiner: '' });
        });
    }
    handleDeleteClick = (id) => {
        this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
        axios.post(DOMEN_SERVER + ACCOUNTING_AGR_DELETE, { email: this.state.email, id: id });
    }
    rows = (id) => {
        axios.post(DOMEN_SERVER + ACCOUNTING_AGR_ROW_REQUEST, { counterparty_id: id }).then(res => {
            this.setState({ rows: res.data });
        });
    }
    countChange = (event) => {
        var name = event.label;
        name = name.replaceAll(' ', '_');
        name = name.replaceAll('.', '_');
        this.setState({ counterparty_id: event.value, counterparty_name: name, con_n: event.label });
        this.rows(event.value);
    }
    servChange = (event) => {
        this.setState({ service_id: event.value, price: event.price });
    }
    saveRecord = () => {
        if (this.state.price != null && this.state.service_id > 0 && this.state.counterparty_id > 0) {
            axios.post(DOMEN_SERVER + ACCOUNTING_NEW_AGR, { email: this.state.email, counterparty_id: this.state.counterparty_id, service_id: this.state.service_id, price: this.state.price }).then(res => {
                this.setState({ rows: res.data });
            });
        } else {
            alert('Укажите значения');
        }
    }
    CustomToolbar = (props) => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: 'Договор_' + this.state.counterparty_name,
                        delimiter: ';',
                        utf8WithBom: true,
                    }}
                />
                Персональные цены для {this.state.con_n}
            </GridToolbarContainer>
        );
    }
    render() {
        let table;
        if (this.state.rows.length > 0) {
            table = <div className="m-5">
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        slots={{ toolbar: this.CustomToolbar }} rowModesModel={this.state.rowModesModel}
                    />
                </Box>
            </div>;
        }
        return (<>
            {this.state.spiner}
            <div className="w-100">
                <div className="mb-5"><h6>Ценовые договора</h6></div>
                <div className="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 ms-5 mb-5 ">
                    <Select options={this.state.counterparties} className="m-1" styles={this.state.customStyles} placeholder="Контрагент" onChange={this.countChange} />
                    <Select options={this.state.servicies} className="m-1" styles={this.state.customStyles} placeholder="Сервисы" onChange={this.servChange} />
                    <div className="d-flex">
                        <Form.Control type="number" name="price" value={this.state.price} placeholder="Цена" onChange={this.inputChange} />
                        <Button variant="primary" className=" m-1" size="sm" onClick={this.saveRecord}>Сохранить</Button></div>
                </div>
                {table}
            </div>
        </>);
    }
}
//Прайс лист
export class PriceList extends React.Component {
    constructor(props) {
        super(props);
        const dd = new Date();
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const dd2 = new Date().toLocaleDateString(undefined, options);
        const time = new Date().toLocaleTimeString();
        this.state = { emal: '', dd: dd,date: dd2, time: time, rows: [], category:[]};

    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_SERVICE_ACTUAL_PRICE).then(res => {
                const category = new Set(res.data.map((item) => item.category)); // создаем коллекцию уникальных значений и отправляем туда массив имен
                console.log(category);
            this.setState({ rows: res.data, category:Array.from(category) });
        });

    }
    //generator PDF
    getTargetElement = () => document.getElementById('content-id');
    pdfGenerate = () => {
        this.setState({ spiner: <Spiner /> });
        const options = {
            filename: "Elida_FF_Price",
            // default is `save`,'open'
            method: 'open',
            // default is Resolution.MEDIUM = 3, which should be enough, higher values
            // increases the image quality but also the size of the PDF, so be careful
            // using values higher than 10 when having multiple pages generated, it
            // might cause the page to crash or hang.
            resolution: Resolution.NORMAL,
            page: {
                // margin is in MM, default is Margin.NONE = 0.'SMALL'
                margin: Margin.SMALL,
                // default is 'A4','letter'
                format: 'A4',
                // default is 'portrait','landscape'
                orientation: 'portrait',
            },
            canvas: {
                // default is 'image/jpeg' for better size performance
                mimeType: 'image/png',
                qualityRatio: 1
            },
            // Customize any value passed to the jsPDF instance and html2canvas
            // function. You probably will not need this and things can break, 
            // so use with caution.
            overrides: {
                // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
                pdf: {
                    compress: true
                },
                // see https://html2canvas.hertzen.com/configuration for more options
                canvas: {
                    useCORS: true
                }
            },
        };

        generatePDF(this.getTargetElement, options);
        this.setState({ spiner: '' });
    }

    render() {
        return (<>
        <div className="panel">
                            <Button className="ms-2 mb-2 mt-1" size="sm" variant="secondary" onClick={this.pdfGenerate}>Открыть PDF</Button>
                        </div>
            <div className="view-id">
                <div id="content-id" className="content-id p-3"> 
                <div className="mt-2 d-flex"><div className="col-header text-start"><cite>Российская Федерация, Московская область, г.Люберцы, ул. Красная  1 лит Р, каб. 115<br /> Tел: +79052222692 <br />Email: team_ff@elida.company </cite>
                                </div><div className="col-header"><img className='elida' src={elida} /></div><div className="col-header text-end"><cite>{this.state.date}<br />{this.state.time}<br />www.elida.company<br />Telegram: @ff_elida</cite></div></div>
                <h5>Актуальные цены на {this.state.dd.toLocaleDateString()}</h5>
                {this.state.category.map((val,i)=>(
                            <div key={i} className="w-100">
                                <h6 className=" mt-3 mb-2 text-primary"><u><i>{val}</i></u></h6>
                                 <table  className="w-100">
                        <thead>
                            <tr className="text-secondary text-center"><td></td><td className="nowrap text-center"><small><i>Наименование</i></small></td>
                            <td className="text-center"><small><i>Цена(руб.)</i></small></td>
                            <td className="text-center"><small><i>Описание</i></small></td></tr>
                            </thead>
                            {this.state.rows.map((row,ri)=>(
                                row.category === val ?
                                <tbody><tr key={ri} ><td className="text-center m-2 border-bottom"><small className="m-2">{ri+1}.</small></td><td className="text-start border nowrap"><small className="m-2">{row.service}</small></td>
                                <td className="text-center border"><small className="m-2">{row.price}</small></td><td className="text-start m-2 border-top border-bottom"><small className="m-2 text-secondary"><i>{row.description}</i></small></td></tr></tbody> :''
                            ))}
                    </table>
                            </div>
                           ))} 
                   
                </div>
            </div>
        </>);
    }
}
//Сводный отчет
export class TotalReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spiner: '', show: false, modal: '', debt_invoice: 0, credit_invoice: 0, salary: 0, money: 0, entries: 0, passive: 0, balance: 0 }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_TOTAL_REPORT, { email: store.email }).then(res => {
            this.setState({
                debt_invoice: res.data.debt_invoice, credit_invoice: res.data.credit_invoice, salary: res.data.salary, money: res.data.money,
                entries: res.data.entries, passive: res.data.passive, balance: res.data.balance, spiner: ''
            });
        });
    }
    modalClose = () => {
        this.setState({ modal: '', show: false });
    }
    passive = () => {
        this.setState({ modal: <Passive />, show: true });
    }
    creditInvoice = () => {
        this.setState({ modal: <CreditInvoice credit='credit' title='Кредиторская задолженность (мы должны)'/>, show: true });
    }
    debtInvoice = () => {
        this.setState({ modal: <CreditInvoice credit='debt' title='Дебиторская задолженность (нам должны)'/>, show: true });
    }
    render() {
        return (<div>
            {this.state.spiner}

            <Modal show={this.state.show} fullscreen={true} onHide={this.modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {this.state.modal}
                </Modal.Body>
            </Modal>
            <h3>Сводный отчет</h3>
            <div>
                <table>
                    <thead></thead>
                    <tbody>
                        <tr><td colSpan={2}><h3 className="text-success">Активы</h3></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary">Денежные средства</b></td><td className="text-end"><b><i>{this.state.money} руб.</i></b></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary" onClick={this.debtInvoice}><u>Дебиторская задолженость</u></b></td><td className="text-end"><b><i>{this.state.debt_invoice} руб.</i></b></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary">Проводки</b></td><td className="text-end"><b><i>{this.state.entries} руб.</i></b></td></tr>
                        <tr><td colSpan={2}><h3 className="text-danger">Пасивы</h3></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary">Заработная плата</b></td><td className="text-end"><b><i>{this.state.salary} руб.</i></b></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary" onClick={this.creditInvoice}><u>Кредиторская задолженость</u></b></td><td className="text-end"><b><i>{this.state.credit_invoice} руб.</i></b></td></tr>
                        <tr><td className="text-start m-1"><b className="link-primary" onClick={this.passive}><u>Фиксированые обязательства</u></b></td><td className="text-end"><b><i>{this.state.passive} руб.</i></b></td></tr>
                    </tbody>
                    <tfoot>
                        <tr><td className="text-start m-1"><b className="text-dark">Баланс организации</b></td><td className="text-end"><b className={this.state.balance > 0 ? 'text-success' : 'text-danger'}>{this.state.balance} руб.</b></td></tr>
                    </tfoot>
                </table>
            </div>
        </div>);
    }
}
//Пассивы
export class Passive extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'name', headerName: 'Наименование', width: 120, headerAlign: 'center', align: 'left' },
            { field: 'credit', headerName: 'Сумма', width: 300, headerAlign: 'center', align: 'left' },
            {
                field: 'actions', headerName: '', width: 120, headerAlign: 'center', type: 'actions', cellClassName: 'actions', getActions: ({ id }) => {
                    const isInEditMode = this.state.rowModesModel[id]?.mode === GridRowModes.Edit;
                    return [
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={() => this.handleDeleteClick(id)}
                            color="inherit"
                        />,

                    ];
                },

            },
        ];
        this.state = { email: '', rows: [], col: col, rowModesModel: {}, spiner: '', name: '', credit: 0 };
        this.inputChange = this.inputChange.bind(this);
    }
    inputChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleDeleteClick = (id) => {
        this.setState({ rows: this.state.rows.filter((row) => row.id !== id) });
        axios.post(DOMEN_SERVER + ACCOUNTING_PASSIVE_DELETE, { email: this.state.email, id: id }).then(res => {
            this.setState({ rows: res.data, });
        });
    }
    addPassive = () => {
        if (this.state.name !== '' && this.state.credit > 0) {
            this.setState({ spiner: <Spiner /> })
            axios.post(DOMEN_SERVER + ACCOUNTING_PASSIVE_ADD, { email: this.state.email, name: this.state.name, credit: this.state.credit }).then(res => {
                this.setState({ rows: res.data, name: '', credit: 0, spiner: '' });
            });
        } else {
            alert('не указаны значения')
        }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ email: store.email, spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_PASSIVIES).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
    }
    handleClose = () => {
        this.props.modalClose();
    }
    render() {
        return (<div>
            {this.state.spiner}
            <h3>Фиксированные рассходы (аренда)</h3>
            <div ><input type="text" name="name" value={this.state.name} onChange={this.inputChange} className="m-2" />
                <input type="number" name="credit" value={this.state.credit} onChange={this.inputChange} className="m-2" />
                <Button variant="success" size="sm" onClick={this.addPassive}>Добавить</Button>
            </div>
            <div className="mt-5">

                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rowModesModel={this.state.rowModesModel}
                    />
                </Box>
            </div>
        </div>);
    }
}
//Кредиторская / Дебеторская задолженность
export class CreditInvoice extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'Name', headerName: 'Контрагент', width: 300, headerAlign: 'center', align: 'left' },
            { field: 'Credit', headerName: 'Сумма', width: 200, headerAlign: 'center', align: 'center' },
        ];
        this.state = { email: '', rows: [], col: col }
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_CREDIT_INVOICE, { email: store.email, credit: this.props.credit }).then(res => {
            this.setState({ rows: res.data, spiner: '' });
        });
    }
    render() {
        return (<div>
            {this.state.spiner}
            <h3>{this.props.title}</h3>
            <div className="mt-5">

                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rowModesModel={this.state.rowModesModel}
                    />
                </Box>
            </div>
        </div>);
    }

}
//Дивиденд_ИД
export class DividendUser extends React.Component {
    constructor(props) {
        super(props);
        const col = [
            { field: 'accrual', headerName: 'Начислено', width: 200, headerAlign: 'center', align: 'left' },
            { field: 'received', headerName: 'Получено', width: 200, headerAlign: 'center', align: 'center' },
            { field: 'created_at', headerName: 'Время', width: 200, headerAlign: 'center', align: 'center' },
            { field: 'create_name', headerName: 'Исполнил', width: 200, headerAlign: 'center', align: 'center' },
        ];
        this.state = { email: '', rows: [], col: col, accrual:0, received:0,}
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_DIVIDEND_USER, { id: this.props.id }).then(res => {
            this.setState({ rows: res.data.rows, accrual:res.data.accrual, received:res.data.received,  spiner: '' });
        });
    }
    render() {
        return (<div>
            {this.state.spiner}
            <h3>{this.props.name}</h3>
            <div className="mt-5">
            <div>Начисленино: {this.state.accrual}</div>
            <div>Получено: {this.state.received}</div>
            <div>Отстаток: {this.state.accrual-this.state.received}</div>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <DataGrid rows={this.state.rows} columns={this.state.col} getRowId={(row) => row.id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rowModesModel={this.state.rowModesModel}
                    />
                </Box>
            </div>
        </div>);
    }

}
//Дивиденды
export class Dividends extends React.Component {
    constructor(props) {
        super(props);
        this.state = { spiner: '',sum:'', rows:[], modal:''}
    }
    componentDidMount() {
        const store = JSON.parse(localStorage.getItem('user'));
        axios.defaults.headers.common['Authorization'] = `Bearer ${store.token}`;
        axios.defaults.headers.common['Accept'] = 'application/json';
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (error.response.statusText == "Unauthorized") {
                return SessionEndend();
            }
            return Promise.reject(error);
        });
        this.setState({ spiner: <Spiner /> })
        axios.post(DOMEN_SERVER + ACCOUNTING_DIVIDENDS).then(res => {
            this.setState({
                rows: res.data.data, sum: res.data.sum, spiner: ''});
        });
    }
    modalClose = () => {
        this.setState({ modal: '', show: false });
    }
    dividend_user = (id) => {
       this.setState({ modal: <DividendUser id={id.id} name={id.name}/>, show: true });
    }
  
    render() {
        return (<div>
            {this.state.spiner}

            <Modal show={this.state.show} fullscreen={true} onHide={this.modalClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {this.state.modal}
                </Modal.Body>
            </Modal>
            <h3>Дивиденды</h3>
            <div>
                <table>
                    <thead>
                        <tr className="text-secondary"><td><small className="m-1">Дольщик</small></td><td><small  className="m-1">Начислено</small></td><td><small  className="m-1">Получено</small></td><td><small  className="m-1">Остаток</small></td><td><small  className="m-1">Объем</small></td></tr>  
                    </thead>
                    <tbody>
                       {this.state.rows.map((val,i)=>(
                        <tr key={i} className="border"><td className="text-start"><small  className="m-1"><b onClick={()=>this.dividend_user({id:val.id,name:val.name})}>{val.name}</b></small></td><td className="border"><small  className="m-1">{val.accrual} ₽</small></td><td className="border text-success"><small className="m-1">{val.received} ₽</small></td><td className="border text-danger"><small className="m-1">{val.balance} ₽</small></td><td className="border text-end text-info"><small className="m-1">{val.per} %</small></td></tr>
                       ))}
                    </tbody>
                    <tfoot>
                        <tr><td colSpan={3}><b className="text-dark">Общий остаток</b></td><td colSpan={2}><b className={'text-danger'}>{this.state.sum} руб.</b></td></tr>
                    </tfoot>
                </table>
            </div>
        </div>);
    }
}
//Отчеты 
export class Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = { body: '' }
    }
    price = () => {
        this.setState({ body: <PriceList /> });
    }
    totalReport = () => {
        this.setState({ body: <TotalReport /> });
    }
    dividends = () => {
        this.setState({ body: <Dividends /> });
    }

    render() {
        return (<div>
            <h3>Отчеты</h3>
            <div className="w-100 d-flex p-4">
                <Button variant="warning" className="m-1" onClick={this.price} >Прайс</Button>
                <Button variant="warning" className="m-1" onClick={this.totalReport} >Сводный</Button>
                <Button variant="success" className="m-1" onClick={this.dividends} >Дивиденды</Button>

            </div>
            <div>
                {this.state.body}
            </div>
        </div>);
    }
}
